/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

::ng-deep .sub-header {
  padding: 0;
  &__wrapper {
    mat-tab-group {
      .mat-tab-header {
        width: 100%;
        height: 44px;
        margin: 1px 0 0px;
        box-shadow: 0 3.6px 3.6px 0 rgba(0, 0, 0, 0.25);
        background-color: $white;
      }
    }
    .mat-tab-label {
      text-transform: uppercase;
    }
    .mat-tab-label-active {
      opacity: 1;
      .mat-tab-label-content {
        color: #2986ce;
      }
    }
    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
      background-color: #2986ce;
      height: 3px;
    }
  }
}

::ng-deep .mat-elevation-z8 {
  // padding: 0px !important;
  background: #ffffff !important;
}

::ng-deep .cdk-column-status,
::ng-deep .cdk-column-customPackStatus {
  width: 10%;
}

::ng-deep .mat-header-cell {
  padding: 0 10px !important;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

::ng-deep .mat-cell {
  padding: 0 10px !important;
  word-break: break-word !important;
}

::ng-deep .cdk-column-position {
  width: 5%;
}

::ng-deep {
  .slider-toggle-btn {
    position: absolute;

    .mat-checked {
      .mat-slide-toggle-bar {
        background-color: rgb(83, 173, 242);
        &:hover {
          background-color: rgb(83, 173, 242);
        }
      }

      .mat-slide-toggle-thumb {
        background-color: rgb(41 134 206);
      }
    }
  }
  button.btn {
    border-radius: 0px !important;
  }
  .cdk-column-id {
    width: 10%;
  }
}
